* {
  box-sizing: border-box;
  width: 100%;
}
body {font-family: Verdana, sans-serif;}
.mySlides {display: none;}
img {
  vertical-align: middle;
  width: 100%;
  height: 700px;
}

/* Slideshow container */
.slideshow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: block; */
  width: 100%;
  height: auto;
  /* max-width: 1000px; */
  /* position: relative; */
  margin: auto;
  object-fit: cover;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}


/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {font-size: 11px}
}