.progress-container {
width: 100%;
height: 20px;
background: #ccc;
}

.progress-bar {
height: 20px;
background: #04aa6d;
width: 100%;
}

.content {
padding: 100px 0;
margin: 50px auto 0 auto;
width: 100%;
}