.management-services {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: auto;
    gap: 100px;
    @media screen and (max-width: 912px) {
        gap: 20px;
    }
    @media (max-width: 768px) and (orientation: portrait) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.energy-management {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 450px;
    height: 450px;
    background-image: url("https://media.istockphoto.com/id/1418476287/es/foto/empresario-que-analiza-el-balance-financiero-de-la-empresa-trabajando-con-gr%C3%A1ficos-digitales.webp?b=1&s=612x612&w=0&k=20&c=W2GudfweeX4Z3sJsNtMrzaUe40e5NcOor5aHkQKm1xs=");
    background-size: 450px 450px;
    border-radius: 225px;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity: 1;
    gap: 0px;
}

.emissions-management {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 450px;
    height: 450px;
    background-image: url("https://cdn.pixabay.com/photo/2017/02/10/13/09/landscape-2055140_1280.jpg");
    background-size: 450px 450px;
    border-radius: 225px;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity: 1;
    gap: 0px;
}

.training-management {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 450px;
    height: 450px;
    background-image: url("https://images.unsplash.com/photo-1524178232363-1fb2b075b655?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bGVhcm5pbmd8ZW58MHx8MHx8fDA%3D");
    background-size: 450px 450px;
    border-radius: 225px;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity: 1;
    gap: 0px;
}

.im-management {
    width: 350px;
    height: 350px;
}

.text-management {
    font-family: 'poppins';
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 2px;
    color: white;
}

.buttonmore {
    margin-top: 20px;
    width: 160px;
    height: 40px;
    border-radius: 8px;
    border: none;
    background: #65A056;
    font-family: 'poppins';
    font-size: 20px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}

.buttonmore:hover {
    background: whitesmoke;
    color: #65A056;
    border: 1px solid #65A056;
}

@media (max-width: 912px) {
    .management-services {
        gap: 20px;
    }

    .energy-management {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 250px;
        height: 250px;
        background-image: url("https://media.istockphoto.com/id/1418476287/es/foto/empresario-que-analiza-el-balance-financiero-de-la-empresa-trabajando-con-gr%C3%A1ficos-digitales.webp?b=1&s=612x612&w=0&k=20&c=W2GudfweeX4Z3sJsNtMrzaUe40e5NcOor5aHkQKm1xs=");
        background-size: 250px 250px;
        border-radius: 125px;
        background-repeat: no-repeat;
        cursor: pointer;
        opacity: 1;
        gap: 0px;
    }

    .emissions-management {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 250px;
        height: 250px;
        background-image: url("https://cdn.pixabay.com/photo/2017/02/10/13/09/landscape-2055140_1280.jpg");
        background-size: 250px 250px;
        border-radius: 125px;
        background-repeat: no-repeat;
        cursor: pointer;
        opacity: 1;
        gap: 0px;
    }

    .training-management  {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 250px;
        height: 250px;
        background-image: url("https://images.unsplash.com/photo-1524178232363-1fb2b075b655?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bGVhcm5pbmd8ZW58MHx8MHx8fDA%3D");
        background-size: 250px 250px;
        border-radius: 125px;
        background-repeat: no-repeat;
        cursor: pointer;
        opacity: 1;
        gap: 0px;
    }
}

@media (max-width: 642px) {
    .management-services {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .energy-management {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 350px;
        height: 350px;
        background-image: url("https://media.istockphoto.com/id/1418476287/es/foto/empresario-que-analiza-el-balance-financiero-de-la-empresa-trabajando-con-gr%C3%A1ficos-digitales.webp?b=1&s=612x612&w=0&k=20&c=W2GudfweeX4Z3sJsNtMrzaUe40e5NcOor5aHkQKm1xs=");
        background-size: 350px 350px;
        border-radius: 175px;
        background-repeat: no-repeat;
        cursor: pointer;
        opacity: 1;
        gap: 0px;
    }

    .emissions-management {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 350px;
        height: 350px;
        background-image: url("https://cdn.pixabay.com/photo/2017/02/10/13/09/landscape-2055140_1280.jpg");
        background-size: 350px 350px;
        border-radius: 175px;
        background-repeat: no-repeat;
        cursor: pointer;
        opacity: 1;
        gap: 0px;
    }

    .training-management {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 350px;
        height: 350px;
        background-image: url("https://images.unsplash.com/photo-1524178232363-1fb2b075b655?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bGVhcm5pbmd8ZW58MHx8MHx8fDA%3D");
        background-size: 350px 350px;
        border-radius: 175px;
        background-repeat: no-repeat;
        cursor: pointer;
        opacity: 1;
        gap: 0px;
    }
}
