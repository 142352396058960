.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 10px;
}

.follow-us {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
    height: 100%;
}

.text-footer {
    font-family: 'poppins';
    font-size: 27px;
    font-weight: 550;
    color: #FFFFFF;
}

.icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 30%;
    gap: 30px;
    margin-top: 20px;
}

.icon-socialmedia-linkedin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    --bg-opacity: 1;
    background-color: rgba(255,255,255,var(--bg-opacity));
    color: #0496ff;
    border-radius: 15px;
    background: black;
    border: 1px solid #0496ff;
}

.fa:hover {
    opacity: 1;
}

.fa-linkedin-in svg {
    fill: #0e76a8;
    transition: transform 0.3s;
}

.fa-linkedin-in:hover svg {
    transform: scale(2.5);
}

.icon-socialmedia-instagram  {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    --bg-opacity: 1;
    background-color: rgba(255,255,255,var(--bg-opacity));
    color: #0496ff;
    border-radius: 15px;
    background: black;
    border: 1px solid #C13584;
}

.fa-instagram svg  {
    display: inline-block;
    background: radial-gradient(
      circle at 33% 100%,
      #fed373 4%,
      #f15245 30%,
      #d92e7f 62%,
      #9b36b7 85%,
      #515ecf
    );
    padding: 2px;
    border-radius: 10%;
    transition: transform 0.3s;
}

.fa-instagram:hover svg {
    transform: scale(2.5);
}

.fa-instagram svg path {
    fill: white;
}

.icon-socialmedia-whatsapp  {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    --bg-opacity: 1;
    background-color: rgba(255,255,255,var(--bg-opacity));
    color: #0496ff;
    border-radius: 15px;
    background: black;
    border: 1px solid #25D366;
}
  
.fa-whatsapp svg {
    fill: #25D366;
    transition: transform 0.3s;
}

.fa-whatsapp:hover svg {
    transform: scale(2.5);
}

.new-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: auto;
}

.inside-media {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    margin-top: 10px;
}

.text-link {
    font-family: 'poppins';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #FFFFFF;
}

@media (max-width: 912px) {
    .footer-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .follow-us {
        width: 100%;
    }

    .new-container {
        margin-bottom: 30px;
    }
}

@media (max-width: 642px) {
    .footer-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .follow-us {
        width: 100%;
    }

    .new-container {
        margin-bottom: 15px;
    }
}