.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 100%;
    margin-left: 0px;
    gap: 15px;
    cursor: pointer;
}

.image-logo {
    width: 62px;
    height: 62px;
}

.nombre-logo {
    width: 200px;
    height: 90px;
}

@media (max-width: 642px){
    .logo {
        height: 80%;
        margin-left: 20px;
    }

    .image-logo {
        width: 70px;
        height: 70px;
    }
    
    .nombre-logo {
        width: 500px;
        height: 60px;
    }
}

@media (max-width: 912px){
    .logo {
        height: 80%;
        margin-left: 20px;
    }

    .image-logo {
        width: 70px;
        height: 70px;
    }
    
    .nombre-logo {
        width: 500px;
        height: 60px;
    }
}