.main-wrapped {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: white;
}

.scrollbar {
    display: flex;
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 10000;
}

.header {
    display: flex;
    width: 100%;
    height: auto;
    position: sticky;
    top: 15px;
}

.animation-wrapped {
    display: flex;
    width: 100%;
    height: auto;
}

.video-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.message-container {
    position: absolute;
    top: 50%;
    left: 15%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    transform: translate(-30%, -40%);
    padding: 10px;
    width: 43%;
    text-align: left;
}

.text-message {
    font-family: 'acummin variable concept';
    font-size: 50px;
    font-weight: 900;
    letter-spacing: 2px;
    color: rgba(255, 255, 255, 1);
    line-height: 50px;
    /* text-transform: uppercase; */
}

.scroll-video {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -10%);
    width: auto;
    height: auto;
    cursor: pointer;
}

.svg-icon {
    width: 100px;
    height: 100px;
}

.scroll-text {
    text-align: center;
    font-family: 'poppins';
    font-size: 24px;
    color: white;
    margin-top: 5px;
}

.body-wrapped {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height: auto;
    margin-bottom: 60px;
    /* border: 2px solid blue; */
}

.auxlem {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: inherit;
    font-weight: inherit;
    font-size: inherit;
    word-wrap: break-word;
    vertical-align: baseline;
}

.scrollBut {
    display: block;
    height: 106px;
    width: 106px;
    overflow: hidden;
    text-indent: -1100px;
    background: url(https://energyg.es/overwrite/skins/svg/icon-scroll.svg) no-repeat;
    background-size: 105px 105px;
}

.slogan {
    width: 100%;
    height: 90px;
    font-family: georgia;
    font-size: 50px;
    letter-spacing: 8px;
    line-height: 70px;
    font-weight: normal;
    margin-top: 80px;
    margin-bottom: 40px;
}

.aboutus {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.title-about {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-family: 'poppins';
    font-size: 30px;
    margin-bottom: 30px;
    font-size: 45px;
    font-weight: 600;
    letter-spacing: 4px;
    color: #0A5319;
    width: 100%;
    height: auto;
}

.content-about {
    display: flex;
    flex-direction: row;
    font-family: 'poppins';
    font-size: 20px;
    color: #FFFFFF;
    text-align: justify;
    width: 100%;
    height: auto;
}

.content01 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 480px;
    text-align: justify;
    padding: 40px;
    background-color: black;
}

.content02 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 480px;
    text-align: justify;
    padding: 40px;
    background-color: rgb(173 206 108);
}

.mission-vision {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}

.mission {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: 480px;
    background-color: rgb(173 206 108);
}

.title-mv {
    font-family: 'poppins';
    font-size: 35px;
    letter-spacing: 8px;
    color: #FFFFFF; 
}

.im-tex-mv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 50%;
    margin-top: 30px;
    gap: 0px;
}

.img-mv {
    width: 70px;
    height: 70px;
}

.white-img {
    filter: invert(100%);
}

.title-sub {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'poppins';
    font-size: 35px;
    letter-spacing: 8px;
    color: #0A5319;
}

.title-vp {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'poppins';
    font-size: 45px;
    font-weight: 600;
    letter-spacing: 4px;
    color: #0A5319;
}

.content-mission {
    display: flex;
    text-align: start;
    font-family: 'poppins';
    font-size: 20px;
    text-align: justify;
    padding: 55px;
    color: #FFFFFF;
}

.vision {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: 480px;
    background-color: #000000;
}

.content-vision {
    font-family: 'poppins';
    font-size: 20px;
    text-align: justify;
    padding: 40px;
    color: #FFFFFF;
}

.values {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.list-vp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 160px;
}

.value {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 260px;
    height: 225px;
    font-family: 'poppins';
    font-size: 30px;
    color: #0A5319;
    margin-top: 60px;
    margin-bottom: 60px;
}

.im-v {
    width: 100px;
    height: 100px;
}

.pillars {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.pillar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 260px;
    height: 225px;
    font-family: 'poppins';
    font-size: 30px;
    color: #0A5319;
    margin-top: 60px;
    margin-bottom: 60px;
}

.im-p {
    width: 100px;
    height: 100px;
}

.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-bottom: 100px;
}

.services {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: auto;
    margin-bottom: 200px;
    /* border: 2px solid rgb(206, 7, 173); */
    margin-left: 100px;
    margin-right: 100px;
}

.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 90%;
    margin-bottom: 80px;
    gap: 50px;
}

.img-contactform {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
}

.i-contact {
    width: 60%;
    height: auto;
}

.content-contacform {
    width: 100%;
    height: auto;
}

.footer {
    width: 100%;
    height: 400px;
    background-color: black;
    /* background-image: url("https://images.pexels.com/photos/7527911/pexels-photo-7527911.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"); */
    background-size: 100% 400px;
    /* background-repeat: no-repeat; */
}

.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 10px;
}

.company-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 100%;
}

.follow-us {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40%;
    height: 100%;
}

.text-footer {
    font-family: 'poppins';
    font-size: 27px;
    font-weight: 550;
    color: #FFFFFF;
}

.icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 40%;
    gap: 30px;
    margin-top: 20px;
}

.icon-socialmedia {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    --bg-opacity: 1;
    background-color: rgba(255,255,255,var(--bg-opacity));
    color: #0496ff;
    border-radius: 15px;
}

.fa {
    padding: 20px;
    font-family: 'poppins';
    font-size: 30px;
    width: 500px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
}

.fa:hover {
    opacity: 0.3;
}

.new-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: auto;
}

.inside-media {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    margin-top: 10px;
}


.text-link {
    font-family: 'poppins';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #FFFFFF;
}

@media (max-width: 912px) {
    .message-container {
        display: none;
    }

    .scroll-video {
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -10%);
        width: auto;
        height: auto;
        cursor: pointer;
    }

    .svg-icon {
        width: 50px;
        height: 50px;
    }

    .scroll-text {
        font-size: 16px;
        margin-top: 1px;
    }

    .body-wrapped {
        width: 100%;
    }

    .slogan {
        /* display: none; */
        width: 95%;
        height: 90px;
        font-size: 23px;
        letter-spacing: 4px;
        line-height: 50px;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .title-about {
        font-size: 25px;
        margin-top: 25px;
    }

    .content-about {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .content01 {
        width: 100%;
        height: 600px;
        padding-top: 30px;
        padding-top: 30px;
    }

    .content02 {
        width: 100%;
        height: 600px;
        padding-top: 30px;
        padding-top: 30px;
    }

    .mission-vision {
        display: flex;
        flex-direction: row;
    }

    .mission {
        width: 100%;
        height: 900px;
        padding-bottom: 1000px;
        padding-top: 30px;
    }

    .vision {
        width: 100%;
        height: 900px;
        padding-bottom: 800px;
        padding-top: 30px;
    }

    .list-vp {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .img-contactform {
        display: none;
    }
}

@media (max-width: 642px) {
    .message-container {
        display: none;
    }

    .scroll-video {
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -10%);
        width: auto;
        height: auto;
        cursor: pointer;
    }

    .svg-icon {
        width: 50px;
        height: 50px;
    }

    .scroll-text {
        font-size: 16px;
        margin-top: 1px;
    }

    .body-wrapped {
        width: 100%;
    }

    .slogan {
        /* display: none; */
        width: 95%;
        height: 90px;
        font-size: 23px;
        letter-spacing: 4px;
        line-height: 40px;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .title-about {
        font-size: 25px;
        margin-top: 25px;
    }

    .content-about {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .content01 {
        width: 100%;
        height: 500px;
        padding-bottom: 30px;
        padding-top: 30px;
    }

    .content02 {
        width: 100%;
        height: 500px;
        padding-bottom: 30px;
        padding-top: 30px;
    }

    .mission-vision {
        display: flex;
        flex-direction: column;
    }

    .mission {
        width: 100%;
        height: 800px;
        padding-bottom: 900px;
        padding-top: 30px;
    }

    .vision {
        width: 100%;
        height: 800px;
        padding-bottom: 900px;
        padding-top: 30px;
    }

    .list-vp {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .img-contactform {
        display: none;
    }
}