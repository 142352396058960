.form {
    display: flex;
    justify-content: center;
    /* align-items: flex-start; */
    align-items: center;
    margin: auto;
    flex-direction: column;
    width: 500px;
    font-family: 'poppins';
    font-size: 20px;
}

input {
    width: 100%;
    height: 45px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);

    &:focus {
    border: 2px solid rgba(0, 206, 158, 1);
    }
}

textarea {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);

    &:focus {
    border: 2px solid rgba(0, 206, 158, 1);
    }
}

label {
    display: flex;
    margin-top: 1rem;
    text-align: left;
}

input[type="submit"] {
    width: 50%;
    margin-top: 2rem;
    cursor: pointer;
    background: #65A056;
    color: white;
    border: none;
}

.pointer:hover {
    background: whitesmoke;
    color: #65A056;
    border: 1px solid #65A056;
}


/* Estilo para el modal */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'poppins';
    font-size: 28px;
    background-color: #65A056;
    border: 4px solid #65A056;
    padding: 20px;
    width: 450px;
    height: 180px;
    text-align: center;
    position: relative;
    color: #FFFFFF;
  }
  
  /* Estilo para el botón de cierre */
  .close {
    position: absolute;
    top: 10%;
    right: 6%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-weight: 600;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #FFFFFF;
  }

  .close:hover {
    background-color: #ddd; /* Cambiar el color de fondo en el hover */
    color: #65A056;
  }



@media (max-width: 642px) {
    .form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 220px;
    }
}

@media (max-width: 912px) {
    .form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 250px;
    }
}