.ods-wrapped {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.body-ods {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height: auto;
}

.title-ods {
    display: flex;
    width: 80%;
    height: auto;
    text-align: center;
    margin-top: 80px;
}

.title-ods h2 {
    font-family: 'poppins';
    font-size: 45px;
    font-weight: 700;
    line-height: 48px;
    color: #0A5319;
}

.container-ods {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    gap: 10px;
}

.container-ods-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.obs {
    width: 330px;
    height: 330px;
}

.a-ods {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.obs-7 {
    background-color: rgb(252, 195, 18);
    width: 100%;
    height: 100%;
}

.font-ods {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-align: start;
    font-family: 'poppins';
    font-size: 35px;
    font-weight: 800;
    color: white;

}

.font-ods-content {

    font-family: 'poppins';
    font-size: 25px;
    font-weight: 400;
    color: white;
    padding: 20px;
}

.obs-8 {
    background-color: rgb(162, 25, 66);
    width: 100%;
    height: 100%;
}

.obs-9 {
    background-color: rgb(253, 105, 37);
    width: 100%;
    height: 100%;
}

.obs-11 {
    background-color: rgb(253, 157, 36);
    width: 100%;
    height: 100%;
}

.obs-12 {
    background-color: rgb(191, 139, 46);
    width: 100%;
    height: 100%;
}

.obs-13 {
    background-color: rgb(72, 119, 60);
    width: 100%;
    height: 100%;
}

@media (max-width: 642px){
    .body-ods {
        width: 100%;
    }

    .title-ods {
        margin-top: 50px;
    }

    .title-ods h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 38px;
    }

    .container-ods {
        width: 100%;
        height: auto;
        margin-top: 50px;
    }

    .container-ods-group {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .font-ods {
        font-size: 20px;
        font-weight: 100;
    }
}

@media (max-width: 912px){
    .body-ods {
        width: 100%;
    }

    .title-ods {
        margin-top: 50px;
    }

    .title-ods h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 38px;
    }

    .container-ods {
        width: 100%;
        height: auto;
        margin-top: 50px;
    }

    .obs {
        width: 280px;
        height: 280px;
    }

    .font-ods {
        font-size: 20px;
        font-weight: 100;
    }
}

