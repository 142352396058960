.hamburger {
  cursor: pointer;
  display: block;
  width: 30px;
  height: 22px;
  position: relative;
  transition: .5s ease-in-out;
}

.hamburger span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #333;
  border-radius: 9px;
  transition: .25s ease-in-out;
}

.hamburger span:nth-child(1) {
  top: 0px;
}

.hamburger span:nth-child(2) {
  top: 9px;
}

.hamburger span:nth-child(3) {
  top: 18px;
}

.hamburger.is-active span:nth-child(1) {
  transform: translateY(9px) rotate(135deg);
}

.hamburger.is-active span:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active span:nth-child(3) {
  transform: translateY(-9px) rotate(-135deg);
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px;
  left: 0;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.mobile-menu.is-open {
  max-height: 100vh; /* Ajusta este valor según la altura de tu menú */
}

.menu-item {
  padding: 10px;
  text-decoration: none;
  color: #333;
  border-bottom: 1px solid #ccc;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInDown 0.3s ease forwards;
}

.menu-item:nth-child(1) { animation-delay: 0.3s; }
.menu-item:nth-child(2) { animation-delay: 1s; }
.menu-item:nth-child(3) { animation-delay: 1.5s; }

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
