.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    background: white;
    opacity: 1;
    gap: 0px;
    z-index: 99;
    transition: all 0.3s ease-in;
}

.menu-icon {
    display: none;
    cursor: pointer;
}

.nav-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 22px;
    width: 28px;
    transition: all 100ms ease-in-out;
    cursor: pointer;
}

.nav-toggle .bar {
    height: 4px;
    width: 100%;
    background-color: #0A5319;
    transition: all 100ms ease-in-out;
}

.nav-toggle.x .bar:nth-of-type(1) {
    transform: rotate(45deg);
    transform-origin: top left;
    width: 28px;
}

.nav-toggle.x .bar:nth-of-type(2) {
    opacity: 0;
}

.nav-toggle.x .bar:nth-of-type(3) {
    transform: rotate(-45deg);
    transform-origin: bottom left;
    width: 28px;
}

.logo-name{
    display: flex;
    width: 40%;
}

.menu-bars {
    width: 30px;
    height: 30px;
}

.start {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 165px;
    min-height: 45px;
    border-radius: 30px;
    font-family: 'Poppins';
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #0A5319;
    cursor: pointer;
    transition: transform .2s;
}

.start-hover:hover {
    background: #65A056;
    color: #FFFFFF;
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1); 
}

.font-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-family: 'Poppins';
    letter-spacing: 1px;
    font-size: 22px;
    color: #0A5319;
    transition: transform .2s;
    width: 165px;
    height: 45px;
    border-radius: 30px;
}

.font-bar:hover {
    background: #65A056;
    color: #FFFFFF;
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1); 
}

.mobile-menu {
    position: fixed;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    background-color: #FFFFFF;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 60px;
    z-index: 999999;
}
  
.mobile-menu.is-open {
    max-height: 100vh;
    z-index: 999999;
}

.top-bar-desktop {
    width: 0;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: row;
    gap: 10px;
    margin-right: 60px;
    width: 60%;
    height: auto;
    gap: 30px;
}


@media (max-width: 642px) {
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
    }

    .logo-name {
        margin-left: 50px;
        margin-top: 5px;
        width: 80px;
    }

    .nav-toggle {
        position: fixed;
        right: 25px;
        top: 40px;
    }
    
    .menu-bars {
        position: fixed;
        right: 15px;
        top: 30px;
        width: 30px;
        height: 30px;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
        float: right;
    }

    .start {
        color: #FFFFFF;
    }

    .menu-item {
        display: flex;
        text-align: left;
        padding: 10px;
        text-decoration: none;
        opacity: 0;
        transform: translateY(-20px);
        animation: fadeInDown 0.3s ease forwards;
        margin-bottom: 20px;
        width: 80%;
        margin-left: 20px;
        animation-delay: 0.2s;
    }
      
    .menu-item:nth-child(1) { animation-delay: 0.3s; }
    .menu-item:nth-child(2) { animation-delay: 1s; }
    .menu-item:nth-child(3) { animation-delay: 1.5s; }
    .menu-item:nth-child(4) { animation-delay: 2.0s; }
    .menu-item:nth-child(5) { animation-delay: 2.5s; }

    .iten-font {
        color: #65A056;
        font-family: 'Poppins';
        letter-spacing: 2px;
        font-size: 22px;
        font-weight: 800;
    }

    @keyframes fadeInDown {
        from {
            opacity: 0;
            transform: translateY(-20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .top-bar-desktop {
        display: none;
    }
}

@media (max-width: 912px) {
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .logo-name {
        /* display: none; */
        margin-left: 50px;
        margin-top: 5px;
        width: 200px;
    }

    .nav-toggle {
        position: fixed;
        right: 25px;
        top: 40px;
    }
    
    .menu-bars {
        position: fixed;
        right: 15px;
        top: 30px;
        width: 60px;
        height: 60px;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
        float: right;
    }

    .start {
        color: #FFFFFF;
    }

    .menu-item {
        display: flex;
        text-align: left;
        padding: 10px;
        text-decoration: none;
        opacity: 0;
        transform: translateY(-20px);
        animation: fadeInDown 0.3s ease forwards;
        margin-bottom: 20px;
        width: 80%;
        margin-left: 20px;
        animation-delay: 0.2s;
    }
      
    .menu-item:nth-child(1) { animation-delay: 0.3s; }
    .menu-item:nth-child(2) { animation-delay: 1s; }
    .menu-item:nth-child(3) { animation-delay: 1.5s; }
    .menu-item:nth-child(4) { animation-delay: 2.0s; }
    .menu-item:nth-child(5) { animation-delay: 2.5s; }

    .iten-font {
        color: #65A056;
        font-family: 'Poppins';
        letter-spacing: 2px;
        font-size: 22px;
        font-weight: 800;
    }

    @keyframes fadeInDown {
        from {
            opacity: 0;
            transform: translateY(-20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .top-bar-desktop {
        display: none;
    }
}

@media (min-width: 642px) {
    .mobile-menu.is-open {
        display: none;
    }
}
