.page-wrapped {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.header-activity {
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 50px;
    /* border: 2px solid rgb(87, 42, 165); */
}

.container-activity {
    display: flex;
    width: 70%;
    height: auto;
    /* border: 2px solid rgb(87, 42, 165); */
}

.text-activity {
    font-family: 'poppins';
    font-size: 45px;
    font-weight: 600;
    letter-spacing: 3px;
    color: #0A5319;
}

.value-proposition {
    width: 70%;
    height: auto;
    font-family: 'acumin variable concept';
    font-size: 40px;
    letter-spacing: 1px;
    line-height: 40px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.content-activities {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: auto;
    /* gap: 10px; */
}

.im-activities {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 600px;
    background-repeat: no-repeat;
    border-radius: 20px;
}

.text-activities {
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 70%;
    height: auto;
}

.title-sub {
    font-family: 'poppins';
    font-size: 28px;
    letter-spacing: 1px;
    font-weight: 600;
    color: #65A056;
    text-align: justify;
    margin-bottom: 50px;
}

.list-activitie {
    width: 80%;
    height: auto;
}

.list-activities {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'poppins';
    font-size: 18px;
    width: 100%;
    text-align: left;
}

.list-serveces {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: justify;
    margin-bottom: 15px;
}

.list-icon {
    width: 20px;
    height: auto;
    margin-right: 10px;
}

.services {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 50px;
}

.footer {
    width: 100%;
    height: 400px;
    background-color: black;
    background-size: 100% 400px;
}

@media (max-width: 642px) {
    .text-activity {
        font-size: 35px;
        font-weight: 600;
        letter-spacing: px;
    }
    
    .value-proposition {
        font-size: 24px;
        line-height: 28px;
    }

    .content-activities {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .im-activities {
        display: none;
    }

    .text-activities {
        display: flex;
        text-align: justify;
        flex-direction: column;
        width: 80%;
    }

    .title-sub {
        text-align: center;
        line-height: 30px;
        font-weight: 800;
    }
}

@media (max-width: 912px) {
    .text-activity {
        font-size: 35px;
        font-weight: 600;
        letter-spacing: px;
    }
    
    .value-proposition {
        font-size: 24px;
        line-height: 28px;
    }

    .content-activities {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .im-activities {
        display: none;
    }

    .text-activities {
        display: flex;
        text-align: justify;
        flex-direction: column;
        width: 80%;
    }

    .title-sub {
        text-align: center;
        line-height: 30px;
        font-weight: 800;
    }
}